import type { FunctionComponent } from 'react';
import type { TypeBadgeProps } from './types';

const colors = {
  orange: '#f68217',
  purple: '#8217f6',
  red: '#d32929',
  black: '#262626',
  beige: '#998879',
};

const textStyle = {
  orange: 'text-secondary-500',
  purple: 'text-tertiary-500',
  red: 'text-status-error',
  black: 'text-primary-900',
  beige: 'text-[#998879]',
};

const backgroundStyle = {
  orange: 'bg-secondary-500',
  purple: 'bg-tertiary-500',
  red: 'bg-status-error',
  black: 'bg-primary-900',
  beige: 'bg-[#998879]',
};

const borderStyle = {
  orange: 'border-secondary-500',
  purple: 'border-tertiary-500',
  red: 'border-status-error',
  black: 'border-primary-900',
  beige: 'border-[#998879]',
};

/**
 * Badge
 */
export const Badge: FunctionComponent<TypeBadgeProps> = ({
  className = '',
  style = 'orange',
  outline = false,
  type = 'basic',
  backgroundColor,
  textColor,
  fullRounded,
  roundedTopLeft,
  roundedTopRight,
  roundedBottomLeft,
  roundedBottomRight,
  children,
}: TypeBadgeProps) => {
  const borderClassName = style !== 'custom' ? borderStyle[style] : '';
  const backgroundClassName = style !== 'custom' ? backgroundStyle[style] : '';
  const textClassName = style !== 'custom' ? textStyle[style] : '';

  const baseStyles = `inline-block min-w-12 max-w-max px-1 py-0.5 text-center z-10 ${
    outline
      ? `border bg-transparent ${borderClassName} ${textClassName}`
      : `${backgroundClassName} text-typo-alternative`
  }`;

  if (type === 'oblique') {
    return (
      <p
        style={{
          backgroundColor: style === 'custom' ? backgroundColor : colors[style],
          color: style === 'custom' ? textColor : '#ffffff',
        }}
        className={`${baseStyles} u-subtitle u-subtitle--s absolute left-0 top-0 origin-top-right -translate-x-[30%] -rotate-45 !py-0 shadow-xl before:absolute before:right-full before:top-0 before:-mx-px before:h-full before:w-full before:bg-inherit before:content-[''] after:absolute after:left-full after:top-0 after:-mx-px after:h-full after:w-full after:bg-inherit after:content-[''] ${className}`}
      >
        {children}
      </p>
    );
  }

  if (type === 'bottom') {
    return (
      <p
        style={{
          ...(style === 'custom' && {
            ...(outline
              ? {
                  borderColor: textColor,
                  color: textColor,
                }
              : {
                  color: textColor,
                  backgroundColor,
                }),
          }),
        }}
        className={`${baseStyles} u-subtitle u-subtitle--s absolute bottom-4 left-1/2 -translate-x-1/2 rounded-md ${className}`}
      >
        {children}
      </p>
    );
  }

  return (
    <span
      style={{
        ...(style === 'custom' && {
          ...(outline
            ? {
                borderColor: textColor,
                color: textColor,
              }
            : {
                color: textColor,
                backgroundColor,
              }),
        }),
      }}
      className={`${baseStyles} u-body u-body--s ${fullRounded ? 'rounded-2xl' : ''} ${roundedTopLeft ? 'rounded-tl-2xl' : ''} ${roundedTopRight ? 'rounded-tr-2xl' : ''} ${roundedBottomLeft ? 'rounded-bl-2xl' : ''} ${roundedBottomRight ? 'rounded-br-2xl' : ''} ${className}`}
    >
      {children}
    </span>
  );
};
